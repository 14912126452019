import firebase from "firebase";

export const app = firebase.initializeApp({
    apiKey: "AIzaSyC8SLw_pN-kSwaN8WEcQbBtkWMXofzBe2A",
    authDomain: "lawwiz-5ebfd.firebaseapp.com",
    databaseURL: "https://lawwiz-5ebfd.firebaseio.com",
    projectId: "lawwiz-5ebfd",
    storageBucket: "lawwiz-5ebfd.appspot.com",
    messagingSenderId: "666707876336",
    appId: "1:666707876336:web:1a2cb7749a6608e17be535"
});
