import React from "react"
import * as Icon from "react-feather"
const horizontalMenuConfig = [

  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/admin/dashboard"
  },


  {
    id: "admin/entities",
    title: "Entities",
    type: "item",
    icon: <Icon.Grid size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/admin/entities"
  },

  {
    id: "classifications",
    title: "Classification",
    icon : <Icon.Grid size={20} />,
    type: "collapse",
    children: [
      {
        id: "regulators",
        title: "Regulators / Authorities ",
        type: "item",
        icon: <Icon.CheckSquare size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/regulators/:id"
      },
      {
        id: "locations",
        title: "Locations",
        type: "item",
        icon: <Icon.Map size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/locations/:id"
      },
      {
        id: "company_types",
        title: "Company Types",
        type: "item",
        icon: <Icon.Sliders size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/types/:id"
      },

      {
        id: "services",
        title: "Services / Industries",
        type: "item",
        icon: <Icon.Package size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/services/:id"
      },
      {
        id: "events",
        title: "Events",
        type: "item",
        icon: <Icon.Scissors size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/events/:id"
      },
    ]
  },

  {
    id: "repository",
    title: "Repository",
    icon : <Icon.Grid size={20} />,
    type: "collapse",
    children: [
      {
        id: "laws",
        title: "Laws & Regulations",
        type: "item",
        icon: <Icon.Layers size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/laws"
      },
      {
        id: "admin/repository",
        title: "Repository",
        type: "item",
        icon: <Icon.Database size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/repository"
      },
      {
        id: "admin/obligations",
        title: "Obligations",
        type: "item",
        icon: <Icon.Calendar size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/obligations"
      },
    ]
  },
  {
    id: "admin/reports",
    title: "Reports",
    type: "item",
    icon: <Icon.BarChart2 size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/admin/reports"
  },
  {
    id: "admin/settings",
    title: "Settings",
    icon: <Icon.Settings size={20}/>,
    type: "collapse",
    children: [
      {
        id: "admin/users",
        title: "Users",
        type: "item",
        icon: <Icon.Users size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/settings/users"
      },
      {
        id: "admin/law/correct",
        title: "Law Correction",
        type: "item",
        icon: <Icon.Circle size={20}/>,
        // permissions: ["admin", "editor"],
        navLink: "/admin/settings/law"
      },
      // {
      //   id: "admin/roles",
      //   title: "Roles",
      //   type: "item",
      //   icon: <Icon.Key size={20}/>,
      //   permissions: ["admin", "editor"],
      //   navLink: "/admin/settings/roles"
      // },
      // {
      //   id: "admin/permissions",
      //   title: "Permissions",
      //   type: "item",
      //   icon: <Icon.Lock size={20}/>,
      //   permissions: ["admin", "editor"],
      //   navLink: "/admin/settings/permissions"
      // },
      // {
      //   id: "admin/logs",
      //   title: "Activity Logs",
      //   type: "item",
      //   icon: <Icon.Activity size={20}/>,
      //   permissions: ["admin", "editor"],
      //   navLink: "/admin/settings/logs"
      // },
      // {
      //   id: "admin/storage",
      //   title: "Storage",
      //   type: "item",
      //   icon: <Icon.Cloud size={20}/>,
      //   permissions: ["admin", "editor"],
      //   navLink: "/admin/settings/storage"
      // },
      {
        id: "admin/import",
        title: "Import",
        type: "item",
        icon: <Icon.Cloud size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/admin/settings/import"
      }
    ]
  },
  //
  // {
  //   id: "icons",
  //   title: "Icons",
  //   type: "item",
  //   icon: <Icon.Download size={20}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/icons"
  // },


]

export default horizontalMenuConfig
